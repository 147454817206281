
import { defineComponent } from 'vue';
import DashboardGrid from '@/components/DashboardGrid.vue';

export default defineComponent({
  name: 'OrdersTab',
  components: {
    DashboardGrid,
  },
  data() {
    return {
      dashboardOrderPrintTypeGeneral: {
        orderPrintType: 0
      },

      dashboardOrderPrintTypeFramedASF: {
        orderPrintType: 1
      }, 

      dashboardOrderPrintTypeUnframedASF: {
        orderPrintType: 2
      },

      dashboardOrderPrintTypePrintOnly: {
        orderPrintType: 3
      },

      dashboardOrderPrintTypeBespokeMounting: {
        orderPrintType: 4
      },

      dashboardOrderPrintTypeBespokeFraming: {
        orderPrintType: 5
      },

      dashboardOrderPrintTypeBespokeMixed: {
        orderPrintType: 6
      },
    }
  }
});
